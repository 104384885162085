import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { getLinkByPageName } from '../../lib/links';
import { usePageContext } from '../../context/PageContext';

type BreadcrumbData = {
  name: string;
  link: string;
};

type StructuredDataProps = {
  previousBreadcrumbs: BreadcrumbData[];
  currentBreadcrumbName: string;
};

const basePath = process.env.IRONHACK_URL;

const StructuredData = (props: StructuredDataProps): JSX.Element => {
  const { previousBreadcrumbs, currentBreadcrumbName } = props;

  const itemListElement = [
    ...previousBreadcrumbs.map(({ name, link }, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name,
      item: new URL(link, basePath).href,
    })),
    {
      '@type': 'ListItem',
      position: previousBreadcrumbs.length + 1,
      name: currentBreadcrumbName,
    },
  ];
  return (
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement,
      })}
    </script>
  );
};

export default function Breadcrumbs(): JSX.Element {
  const { t } = useTranslation(['breadcrumbs']);
  const { pageName } = usePageContext();
  const currentBreadcrumbName = t(`breadcrumbs.${pageName}`);

  const previousBreadcrumbs: BreadcrumbData[] = [
    { name: 'Home', link: getLinkByPageName('home') },
  ];

  return (
    <>
      <Breadcrumb separator={<ChevronRightIcon boxSize="18px" color="white" />}>
        {previousBreadcrumbs.map(({ name, link }) => (
          <BreadcrumbItem key={`breadcrumb-${name}`}>
            <BreadcrumbLink as={Link} color="white" textStyle="body5" to={link}>
              {name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
        <BreadcrumbItem>
          <Text as={'span'} color="white" textStyle="body5">
            {currentBreadcrumbName}
          </Text>
        </BreadcrumbItem>
      </Breadcrumb>
      <StructuredData
        currentBreadcrumbName={currentBreadcrumbName}
        previousBreadcrumbs={previousBreadcrumbs}
      />
    </>
  );
}
