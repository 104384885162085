import React, { ReactElement, useRef, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  HStack,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { createIcon } from '@chakra-ui/icons';

import Navbar from '../../components/NavBar';
import BusinessInterestForm from '../../containers/BusinessInterestForm';
import InternalLinks from '../../containers/InternalLinks';
import Footer from '../../containers/Footer';
import { PageContext } from '../../context/PageContext';
import { updateDataLayer } from '../../lib/datalayer';
import { TDatoCmsGraphQlAssets } from '../../types/datocms';
import * as gaEvents from './ga-event';
import Banner from '../../components/Banner';
import useUserLocation from '../../hooks/useUserLocation';

type DatoCmsSourceGraphQlResponse<T> = {
  edges: Array<{ node: T }>;
};

type URecord = Record<string, unknown>;
type BenefitRecord = {
  id: string;
  title: string;
  description: string;
  image: { alt: string; gatsbyImageData: IGatsbyImageData };
};
type CompanyRecord = {
  id: string;
  title: string;
  logo: { alt: string; gatsbyImageData: IGatsbyImageData };
};

type EnterpriseData = {
  allDatoCmsBtobTestimonial: DatoCmsSourceGraphQlResponse<URecord>;
  allDatoCmsNews: DatoCmsSourceGraphQlResponse<URecord>;
  allDatoCmsStat: DatoCmsSourceGraphQlResponse<URecord>;
  allDatoCmsAssetsByPage: TDatoCmsGraphQlAssets;
  datoCmsCompanyShowcase: {
    btob: Array<Record<string, unknown>>;
    btobCollaborations: Array<Record<string, unknown>>;
  };
  stats: DatoCmsSourceGraphQlResponse<Record<string, string>>;
  benefitList: {
    title: string;
    cards: BenefitRecord[];
  };
  companyList: {
    title: string;
    companies: CompanyRecord[];
  };
};

type StatProps = {
  stat: string;
  label: string;
};

type EnterpriseProps = PageProps<EnterpriseData, PageContext>;

const BRAND_COLOR = {
  plus: { color: 'white', bg: 'yellow.100' },
  graduates: { color: 'white', bg: 'purple.40' },
};

const serviceCtaEvent = {
  'talent-pool': gaEvents.TALENT_POOL_EVENT,
  graduates: gaEvents.GRADUATES_CTA_EVENT,
  training: gaEvents.TRAINING_CTA_EVENT,
};

const Stat = (props: StatProps): React.ReactElement => {
  const { stat, label } = props;

  return (
    <HStack
      borderRadius="md"
      boxShadow="lg"
      fontFamily="Gelion-SemiBold"
      p={1}
      spacing={1}
    >
      <Text fontSize="4rem" fontWeight="regular" letterSpacing={-0.5}>
        {stat}
      </Text>
      <Text
        fontSize="1.6rem"
        lineHeight="1.8rem"
        opacity={0.6}
        textTransform="uppercase"
        w="min-content"
      >
        {label}
      </Text>
    </HStack>
  );
};

const CircleExclamation = createIcon({
  displayName: 'CircleExclamation',
  viewBox: '0 0 20 20',
  d: 'M10 5a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0V6a1 1 0 0 0-1-1Zm.92 8.62a.76.76 0 0 0-.09-.18l-.12-.15a1 1 0 0 0-1.09-.21c-.12.05-.23.12-.33.21a1 1 0 0 0-.21 1.09.9.9 0 0 0 .54.54.94.94 0 0 0 .76 0 .9.9 0 0 0 .54-.54A1 1 0 0 0 11 14v-.2a.64.64 0 0 0-.08-.18ZM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Z',
});

const CircleCheck = createIcon({
  displayName: 'CircleCheck',
  viewBox: '0 0 20 20',
  d: 'm12.72 6.79-4.29 4.3-1.65-1.65a1 1 0 1 0-1.41 1.41l2.35 2.36a1 1 0 0 0 1.41 0l5-5a1 1 0 1 0-1.41-1.42ZM10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Z',
});

const Enterprise = (props: EnterpriseProps): ReactElement => {
  const [interest, setInterest] = useState<string>('');
  const { data } = props;
  const { pageName } = props.pageContext;
  const contactForm = useRef(null);
  const { userLocation } = useUserLocation();

  const scrollToContactForm = (value: string, gaEvent?: object): void => {
    if (gaEvent) updateDataLayer(gaEvent);
    setInterest(value);
    contactForm.current.scrollIntoView({ behavior: 'smooth' });
  };

  const stats = data.stats.edges;
  const { benefitList, companyList, page, serviceList } = data;

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Container
        bg={`center url(${page.headerBackground.url})`}
        display="flex"
        flexDir="column"
        maxW="144rem"
        minH={['47.2rem', null, null, '50rem']}
        p={0}
      >
        <Navbar
          isDarkBackground
          languages={[
            { language: 'English', code: 'en' },
            { language: 'Español', code: 'es' },
          ]}
          pageName={pageName}
        />
        <Flex direction={['column-reverse', null, 'row']} flexGrow={1}>
          <Container
            alignSelf="center"
            color="white"
            maxW="48rem"
            pb={[4, null, 0]}
            pl={[null, null, 8]}
          >
            <Text as="h1" color="white" textStyle="jumbo">
              {page.headerTitle}
            </Text>
            <Text mt={[2, null, null, 3]} textStyle="body4">
              {page.headerDescription}
            </Text>
            <Box mt={[3, null, null, 4]}>
              <Button
                colorScheme="primary"
                onClick={(): void =>
                  scrollToContactForm('', gaEvents.HEADER_CTA_EVENT)
                }
              >
                {page.headerCtaLabel}
              </Button>
            </Box>
          </Container>
          <Spacer />
        </Flex>
      </Container>

      <Box maxW="144rem" mx="auto" px={[2, null, 10]}>
        <Text my={[4, null, null, 6]} textStyle="title1">
          {page.aboutUsTitle}
        </Text>
        <Stack direction={['column', null, 'row']} spacing={[3, null, 5]}>
          <Image
            alt={page.aboutUsImage.alt}
            as={GatsbyImage}
            borderRadius="md"
            image={page.aboutUsImage.gatsbyImageData}
          />
          <Container maxW="56rem">
            <Text fontWeight="bold" textStyle="body4">
              {page.aboutUsSubtitle}
            </Text>
            <Text mt={2} textStyle="body4" whiteSpace="pre-line">
              {page.aboutUsDescription}
            </Text>
          </Container>
        </Stack>
      </Box>

      <SimpleGrid
        maxW="144rem"
        minChildWidth="20rem"
        mx="auto"
        my={[3, null, null, 4]}
        px={[2, null, 10]}
        spacing={3}
      >
        {stats.map(({ node }) => (
          <Stat key={node.key} label={node.title} stat={node.value} />
        ))}
      </SimpleGrid>

      <Box maxW="144rem" mx="auto">
        <Text fontWeight="bold" ml={[2, null, 10]} textStyle="body4">
          {companyList.title}
        </Text>
        <Stack
          direction="row"
          display="inline-block"
          overflowX="auto"
          py={[2, null, 3]}
          spacing={3}
          whiteSpace="nowrap"
        >
          <Box display="inline-block" w={[0, null, 24]} />
          {companyList.companies.map((company) => (
            <Image
              alt={company.logo.alt}
              as={GatsbyImage}
              image={company.logo.gatsbyImageData}
              key={company.id}
            />
          ))}
        </Stack>
      </Box>

      <Stack
        bg={`linear-gradient(240.24deg, rgba(253, 167, 54, 0.1) 0%, rgba(255, 255, 255, 0) 100%), 
        linear-gradient(120.01deg, rgba(45, 197, 250, 0.32) 5.51%, rgba(86, 76, 255, 0.24) 100%);`}
        direction={['column', null, 'row']}
        maxW="144rem"
        mx="auto"
        my={[4, null, 6]}
        pb={6}
        pt={[3, null, null, 6]}
        px={[2, null, 10]}
        spacing={[3, null, null, 5]}
      >
        <Image
          alt={page.challengesImage.alt}
          as={GatsbyImage}
          borderRadius="md"
          image={page.challengesImage.gatsbyImageData}
        />
        <Stack direction={['column', null, 'row']} spacing={[5, null, null, 8]}>
          <List spacing={2}>
            <Text textStyle="body1">{page.challengesTitle}</Text>
            {page.challenges.map((challenge) => (
              <Text
                as={ListItem}
                bg="white"
                borderRadius="sm"
                boxShadow="sm"
                key={challenge.id}
                px={1.5}
                py={2}
                textStyle="body4"
              >
                <ListIcon as={CircleExclamation} color="#F5A623" mr={1.5} />
                {challenge.value}
              </Text>
            ))}
          </List>
          <List spacing={2}>
            <Text textStyle="body1">{page.solutionsTitle}</Text>
            {page.solutions.map((solution) => (
              <Text
                as={ListItem}
                bg="white"
                borderRadius="sm"
                boxShadow="sm"
                key={solution.id}
                px={1.5}
                py={2}
                textStyle="body4"
              >
                <ListIcon as={CircleCheck} color="#2DC5FA" mr={1.5} />
                {solution.value}
              </Text>
            ))}
          </List>
        </Stack>
      </Stack>

      <Box
        bg="electricBlue.10"
        maxW="144rem"
        mx="auto"
        px={[2, null, 10]}
        py={5}
      >
        <Text textStyle="title1">{serviceList.title}</Text>
        {serviceList.services.map((service, i) => (
          <Flex
            bg="white"
            borderRadius="md"
            direction={['column', null, 'row']}
            key={service.id}
            mt={i === 0 ? 4 : 2}
          >
            <Stack maxW={[null, null, '24.8rem']} mx={3} my={4} spacing={2}>
              {BRAND_COLOR[service.brand] && (
                <Text
                  as="span"
                  borderRadius="full"
                  maxW="fit-content"
                  px={1.5}
                  {...BRAND_COLOR[service.brand]}
                >
                  {`IH ${service.brand.toUpperCase()}`}
                </Text>
              )}
              <Text textStyle="body1">{service.title}</Text>
              <Text textStyle="body5">{service.summary}</Text>
              <List spacing={2}>
                {service.summaryItems.map((item) => (
                  <ListItem as={HStack} key={item.id} spacing={1.5}>
                    <CircleCheck boxSize={10} color="#2DC5FA" />
                    <Text as="span" textStyle="body5">
                      {item.value}
                    </Text>
                  </ListItem>
                ))}
              </List>
            </Stack>
            <Divider
              d={['none', null, 'block']}
              h="auto"
              orientation="vertical"
            />
            <Stack maxW="80rem" mb={4} mt={8} mx={[3, null, 5]} spacing={5}>
              {service.processSummary && (
                <Box as="section">
                  <Text textStyle="body2">{service.processTitle}</Text>
                  <Text mt={2} textStyle="body5">
                    {service.processSummary}
                  </Text>
                </Box>
              )}
              {service.benefitList.length > 0 && (
                <Box as="section">
                  <Text textStyle="body2">{service.benefitTitle}</Text>
                  <SimpleGrid
                    minChildWidth="26.4rem"
                    mt={2}
                    spacingX={10}
                    spacingY={3}
                  >
                    {service.benefitList.map((benefit) => (
                      <HStack key={benefit.id} spacing={1.5}>
                        <Text fontSize={6}>{benefit.icon}</Text>
                        <Text textStyle="body5">
                          <Text
                            as="span"
                            fontWeight="normal"
                          >{`${benefit.title}: `}</Text>
                          {benefit.description}
                        </Text>
                      </HStack>
                    ))}
                  </SimpleGrid>
                </Box>
              )}
              {service.testimonialList.length > 0 && (
                <Box as="section">
                  <Text textStyle="body2">{service.testimonialTitle}</Text>
                  {service.testimonialList.map((testimony) => (
                    <Stack
                      direction={['column', null, 'row']}
                      key={testimony.id}
                      mt={3}
                      spacing={2}
                    >
                      <Image
                        alt={testimony.image.alt}
                        as={GatsbyImage}
                        image={testimony.image.gatsbyImageData}
                        minW="27.2rem"
                      />
                      <Stack direction="column" spacing={2}>
                        <Image
                          alt={testimony.company.logo.alt}
                          as={GatsbyImage}
                          image={testimony.company.logo.gatsbyImageData}
                          maxW="max-content"
                        />
                        <Text
                          color="darkBlue.64"
                          fontStyle="italic"
                          textStyle="body4"
                        >
                          {`'${testimony.content}'`}
                        </Text>
                        <Text textStyle="body5">
                          {`${testimony.name} - ${testimony.jobTitle}`}
                        </Text>
                      </Stack>
                    </Stack>
                  ))}
                </Box>
              )}
              <Button
                alignSelf="end"
                onClick={(): void =>
                  scrollToContactForm(
                    service.name,
                    serviceCtaEvent[service.name]
                  )
                }
              >
                {service.ctaLabel}
              </Button>
            </Stack>
          </Flex>
        ))}
      </Box>

      <Box
        bg="#FAFBFF"
        maxW="144rem"
        mx="auto"
        px={[2, null, 10]}
        py={[5, null, 6]}
      >
        <Text textStyle="title1">{benefitList.title}</Text>
        <SimpleGrid minChildWidth="28rem" mt={[3, null, null, 5]} spacing={2}>
          {benefitList.cards.map((benefit) => (
            <Stack
              bg="white"
              borderRadius="md"
              key={benefit.id}
              p={2}
              spacing={2}
            >
              <Image
                alt={benefit.image.alt}
                as={GatsbyImage}
                borderRadius="md"
                image={benefit.image.gatsbyImageData}
              />
              <Text textStyle="body2">{benefit.title}</Text>
              <Text textStyle="body5">{benefit.description}</Text>
            </Stack>
          ))}
        </SimpleGrid>
      </Box>

      <Center
        bg={`center url(${page.formImage.url})`}
        maxW="144rem"
        mx="auto"
        pb={[5, null, null, 14]}
        pt={[2, null, null, 5]}
        px={[2, null, 10]}
        ref={contactForm}
      >
        <BusinessInterestForm
          bg="white"
          borderRadius="sm"
          interest={interest}
          p={[2, null, 6]}
        />
      </Center>
      <InternalLinks />
      <Footer showBreadcrumbs />
    </>
  );
};

export const query = graphql`
  query ($locale: String!, $datoLocale: String!, $pageName: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(banner)|(b2b)|(breadcrumbs)|(footer)|(forms)|(menu)|(seo)|(navigation)/"
        }
      }
    ) {
      ...TranslationFragment
    }
    allDatoCmsBtobTestimonial(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          title
          content {
            value
          }
          image {
            gatsbyImageData(
              width: 700
              layout: FULL_WIDTH
              aspectRatio: 1.7777777
            )
            alt
            title
          }
          video {
            providerUid
          }
        }
      }
    }
    page: datoCmsEnterprisePage(locale: { eq: $locale }) {
      aboutUsTitle
      aboutUsSubtitle
      aboutUsDescription
      aboutUsImage {
        alt
        gatsbyImageData(width: 520)
      }
      formImage {
        url
      }
      solutionsTitle
      solutions {
        id
        value
      }
      challengesTitle
      challengesImage {
        alt
        gatsbyImageData(width: 328)
      }
      headerCtaLabel
      headerBackground {
        url
      }
      headerDescription
      headerTitle
      challenges {
        id
        value
      }
    }
    benefitList: datoCmsCardList(
      locale: { eq: $locale }
      purpose: { eq: "b2b" }
    ) {
      title
      cards {
        id
        title
        description
        image {
          alt
          gatsbyImageData(width: 296)
        }
      }
    }
    stats: allDatoCmsStat(
      filter: {
        locale: { eq: $locale }
        key: { in: ["companies", "alumni", "nationalities", "campuses"] }
      }
    ) {
      edges {
        node {
          title
          value
          key
        }
      }
    }
    serviceList: datoCmsServiceList(
      locale: { eq: $locale }
      purpose: { eq: "b2b" }
    ) {
      title
      services {
        id
        ctaLabel
        brand
        name
        benefitTitle
        processSummary
        processTitle
        summary
        summaryItems {
          id
          value
        }
        title
        testimonialTitle
        testimonialList {
          id
          content
          name
          jobTitle
          image {
            alt
            gatsbyImageData(width: 280)
          }
          company {
            logo {
              alt
              gatsbyImageData(height: 32)
            }
          }
        }
        benefitList {
          description
          icon
          id
          title
        }
        technologyTitle
        technologyList {
          value
        }
      }
    }
    companyList: datoCmsCompanyList(
      locale: { eq: $locale }
      purpose: { eq: "b2b" }
    ) {
      title
      companies {
        id
        logo {
          alt
          gatsbyImageData(height: 64)
        }
      }
    }
    allDatoCmsAssetsByPage(
      filter: { page: { eq: $pageName }, locale: { eq: $datoLocale } }
    ) {
      nodes {
        page
        locale
        assets {
          gatsbyImageData(layout: FULL_WIDTH)
          title
          alt
          customData
          basename
        }
      }
    }
  }
`;

export default Enterprise;
