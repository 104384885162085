import React from 'react';
import { Box, Flex, HStack, Link as ChakraLink, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';

import CourseReport from '../../../static/assets/footer/course-report.svg';
import SwitchUp from '../../../static/assets/footer/switchup.svg';
import FloridaEducation from '../../../static/assets/footer/florida-education.svg';
import IronhackLogo from '../../components/IronhackLogo';
import Breadcrumbs from '../../components/Breadcrumbs';
import { getLinkByPageName } from '../../lib/links';

type FooterProps = {
  showBreadcrumbs: boolean;
};

function Footer(props: FooterProps): JSX.Element {
  const { showBreadcrumbs } = props;
  const { t } = useTranslation(['footer']);

  return (
    <Box bg="black">
      <Flex
        bg="black"
        direction="column"
        margin={[null, null, null, 'auto']}
        maxW={[null, null, null, '1120px']}
        p={[3, null, null, 0]}
      >
        {showBreadcrumbs && (
          <Box
            borderBottom="1px solid rgba(255, 255, 255, .05)"
            py={[2, 2, 2, 3]}
          >
            <Breadcrumbs />
          </Box>
        )}
        <Flex
          flexDirection={['column', 'column', 'column', 'row']}
          justifyContent={['center', 'center', 'center', 'space-between']}
          py={[3, 3, 3, 4]}
          w="100%"
        >
          <Flex
            alignItems="center"
            flexDirection={['column', 'column', 'column', 'row']}
            order={[1, 1, 1, 0]}
          >
            <Flex mt={[2, 2, 2, 0]} order={[1, 1, 1, 0]}>
              <ChakraLink as={Link} to={getLinkByPageName('home')}>
                <IronhackLogo size="l" />
              </ChakraLink>
            </Flex>
            <Flex
              alignItems={['center', 'center', 'center', 'flex-start']}
              direction="column"
              ml={[0, 0, 0, 2]}
              mt={[4, 4, 4, 0]}
              order={[0, 0, 0, 1]}
            >
              <Text as="p" color="lille.100" textStyle="body5">
                {t('footer.address')}
              </Text>
              <Text as="p" color="lille.100" textStyle="body5">
                {t('footer.copyright')}
              </Text>
            </Flex>
          </Flex>
          <Flex order={[0, 0, 0, 1]} width={['100%', '100%', '100%', 'auto']}>
            <HStack justifyContent="center" spacing={4} width="100%">
              <CourseReport height={43} width="auto" />
              <SwitchUp height={43} width="auto" />
              <Flex
                alignItems={[null, null, null, 'center']}
                flexDirection={['column', 'column', 'column', 'row']}
              >
                <Text as="p" color="lille.100" textStyle="body5">
                  {`Licensed by:`}
                </Text>
                <Box ml={[0, 0, 0, 1]} mt={[0.25, 0.25, 0.25, 0]}>
                  <FloridaEducation />
                </Box>
              </Flex>
            </HStack>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Footer;
