import { merge } from 'lodash/fp';

const INTEREST_EVENT = {
  event: 'gaEvent',
  eventCategory: 'B2B::interest',
  eventAction: 'click cta::anchor',
};

export const HEADER_CTA_EVENT = merge(INTEREST_EVENT, {
  eventLabel: 'header::access tech talent',
});
export const GRADUATES_CTA_EVENT = merge(INTEREST_EVENT, {
  eventLabel: 'Graduates',
});
export const TRAINING_CTA_EVENT = merge(INTEREST_EVENT, {
  eventLabel: 'Training',
});
export const TALENT_POOL_EVENT = merge(INTEREST_EVENT, { eventLabel: 'HTD' });
